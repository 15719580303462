var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-3 mb-2 rounded main-container"},[_c('div',{staticClass:"box-header"},[_vm._v("Messages")]),_c('div',{staticClass:"box-body"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table"},[_vm._m(0),_vm._l((_vm.messages),function(message){return _c('tr',{key:message.id},[_c('td',[_vm._v(" "+_vm._s(message.getCounterpart(_vm.$user).username)),_c('br'),_c('br'),_c('span',{staticClass:"bold"},[_vm._v("Date")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("formatTimestamp")(message.created))),_c('br'),_vm._v(" ("+_vm._s(_vm._f("formatTimestamp")(message.created,"fromNow"))+") "),_c('br'),_c('br'),_c('router-link',{attrs:{"to":{
                                name: 'conversation',
                                params: {
                                    userId: message.getCounterpart(_vm.$user).id
                                }
                            }}},[_vm._v(" To full conversation >> ")])],1),_c('td',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.bbCode(message.message))}})])])})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticStyle:{"width":"180px"}},[_vm._v("User")]),_c('th',[_vm._v("Last message")])])
}]

export { render, staticRenderFns }